<template>
  <div class="indexContent">
    <asideBar :showGoback="false" :navMenuList="navMenuList" />
    <div class="main-content flex-fill" :style="{ 'margin-left': isMobile ? '0px' : isCollapse ? '200px' : '60px' }">
      <!-- pc端轮播 -->
      <div class="px-md-5 carousel carouselImage">
        <img class="carouselImg" :src="backgroundImg" alt="" />
        <div class="carouselDiv">
          <el-carousel :interval="4000" type="card">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
              <img class="carouselLiImg" :src="item.image" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <div class="container customize-width px-md-5 carousel carouselImageWap">
        <el-carousel trigger="click" height="200px">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img class="carouselLiImg" :src="item.image" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="header-big no-bg mt-0 mb-0 mb-md-4 pb-0 pt-2 pt-md-5">
        <div class="s-search m-md-0 px-md-5">
          <div id="search" class="s-search mx-auto">
            <div id="search-list-menu" class="mb-2">
              <div class="s-type text-center">
                <div class="s-type-list big tab-auto-scrollbar overflow-x-auto">
                  <div class="anchor" style="position: absolute; left: 50%; opacity: 0;"></div>
                  <a class="ad">聚图搜</a>
                  <div class="tapTabs">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                      <el-tab-pane v-for="item of tabsList" :key="item.key" :label="item.title" :name="item.key"></el-tab-pane>
                    </el-tabs>
                  </div>
                </div>
              </div>
            </div>
            <div class="searchDiv" :class="searAction.key">
              <el-autocomplete
                class="inline-input"
                v-model="searchValue"
                :fetch-suggestions="querySearch"
                placeholder="请输入关键字搜索"
                :trigger-on-focus="false"
              ></el-autocomplete>
              <div class="searchSlot" @click="searchClick">
                <i class="el-icon-search"></i>
              </div>
            </div>
            <div class="popular-search" style="color: #333;">
              <span><!-- <i class="io io-huore icon-fw mr-1"></i> -->热搜：</span>
              <a v-for="(item, index) in resouList" @click="clickJump('/globalSearch?searchValue=' + item)">{{ item }}</a>
            </div>
          </div>
        </div>
      </div>

      <div class="container customize-width px-md-5">
        <div class="content">
          <div class="content-wrap">
            <div class="content-layout ">
              <!-- 白金赞助 -->
              <div class="block-header my-3 my-md-4">
                <img src="@/assets/11111.png" alt="" style="width: 30px;height: 30px;" />
                <h4 class="block-title" style="margin-left: 10px;">白金赞助</h4>
                <div class="flex-fill"></div>
                <a @click="clickJump('/about')">
                  <!-- <i class="io io-xiaoxi icon-fw mr-1"></i> -->

                  联系站长
                  <i class="iconfont icon-arrow-r-m"></i>
                </a>
              </div>
              <div class="platinum">
                <div class="card-grid card-grid2 ">
                  <div v-for="item in guanggaoList" :key="item.id" class="url-card " @click="clickJump2(item.content)">
                    <div class="sponsors-body url-body">
                      <img class="urlImg" :src="item.image" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- 精品推荐 -->
              <div class="block-header my-3 my-md-4">
                <img src="@/assets/11111.png" alt="" style="width: 30px;height: 30px;" />
                <h4 class="block-title" style="margin-left: 10px;">精品推荐</h4>
                <div class="flex-fill"></div>
              </div>
              <div class="guang_list" v-if="deviceType === 'phone'">
                <div class="item1" v-for="item in gugeList" @click="clickJump2(item.content)">
                  <div class="top">
                    <img :src="item.image" alt="" />
                  </div>
                  <div class="top1">
                    {{ item.title }}
                  </div>
                </div>
              </div>

              <div class="pc_guge" v-else>
                <div class="item1" v-for="item in gugeList" @click="clickJump2(item.content)">
                  <div class="top">
                    <img :src="item.image" alt="" />
                  </div>
                  <div class="top1">
                    {{ item.title }}
                  </div>
                </div>
              </div>

              <!-- 谷歌广告结束 -->

              <!-- 新版ggggggggg -->
              <div class="section-box" v-for="item in navMenuList" :key="item.id" :id="'section' + item.id" v-if="deviceType !== 'phone'">
                <div class="block-header my-3 my-md-4">
                  <h4 class="block-title">
                    <!-- <i :class="item.icon" /> -->
                    <img :src="item.image" alt="" style="width: 30px;height: 30px;" />
                    <span style="margin-left: 10px;">{{ item.name }}</span>
                  </h4>
                  <div class="flex-fill"></div>
                  <a @click="clickJump('/gengduo?id=' + item.id)">更多<i class="iconfont icon-arrow-r-m"></i></a>
                </div>
                <div class="card-grid">
                  <div class="category-card" v-for="item2 in item.nav" :key="item2.id">
                    <div class="category-body">
                      <!-- , '/details?id=' + item2.id + '&cid=' + item2.category_id) -->
                      <a class="category-content" @click="clickJump3(item2)">
                        <div class="">
                          <img :src="item2.image" style="width: 60px;height: 60px;border-radius: 80px;" />
                        </div>
                        <div class="contens" style="position: relative;left: 10px;">
                          <strong>{{ item2.title }}</strong>
                          <p>{{ item2.content }}</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <!-- <div class="card-grid card-grid2">
									<div class="url-card" v-for="(items, child) in item.nav" :key="child" >
										<div class="url-body" >
											<a :href="item.link" @click="goDetails(items, item)" class="url-content">
												<div class="url-img">
													<img class="" :src="items.image" :alt="items.title" style="border: 1px solid red;border-radius: 8px;width: 50px;"/>
												</div>
												<div class="url-info">
													<div class="overflowClip_1 mb-1 mb-md-2">
														<strong>{{ items.title }}</strong><span class="badge"
															v-if="items.tagName">{{ items.tagName }}</span>
													</div>
													<p class="overflowClip_2">
														{{ items.content }}
													</p>
												</div>
											</a>
											<div class="url-link" v-if="items.tag">
												<div class="swiper-wrapper">
													<a> {{ items.tag }}</a>
												</div>
											</div>
											<div v-if="items.isShowMoreTag" class="url-details" rel="nofollow">
												<i class="iconfont icon-goto"></i>
											</div>
										</div>
									</div>
								</div>
							 -->
              </div>
              <!-- 新版ggggggggg -->

              <!-- 手机端 -->

              <div class="index_one" v-if="deviceType === 'phone'">
                <div class="item1s" v-for="item in navMenuList" :key="item.id" :id="'section' + item.id">
                  <div class="top" style="display: flex;align-items: center;justify-content: space-between;">
                    <h4 class="block-title">
                      <img :src="item.image" alt="" style="width: 30px;height: 30px;" />
                      <span style="margin-left: 10px;">{{ item.name }}</span>
                    </h4>
                    <a @click="clickJump('/gengduo?id=' + item.id)">更多<i class="iconfont icon-arrow-r-m"></i></a>
                  </div>
                  <div class="list">
                    <div class="item1s" v-for="(items, child) in item.nav" @click="goDetails(items, item)">
                      <div class="left">
                        <img :src="items.image" alt="" />
                        <p>{{ items.title }}</p>
                      </div>
                      <div class="right">
                        <!-- <i class="iconfont icon-arrow-r-m"></i> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 手机端end -->
              <!--最新资讯  -->
              <div class="block-header my-3 my-md-4">
                <h4 class="block-title"><i class="io io-renwu"></i>最新资讯</h4>
                <div class="flex-fill"></div>
                <a @click="clickJump('/flooding')">更多<i class="iconfont icon-arrow-r-m"></i></a>
              </div>
              <div class="card-grid">
                <div class="post-card" v-for="(item, index) in ziXunList" :key="index">
                  <div class="post-body" @click="goZiXun(item)">
                    <div class="post-content">
                      <a class="overflowClip_2"
                        ><strong>{{ item.title }}</strong></a
                      >
                      <div class="post-footer">
                        <div class="post-time">{{ timeAgo(item.createtime * 1000) }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 友情链接 -->
              <div class="block-header my-3 my-md-4">
                <h4 class="block-title"><i id="friendlink" class="iconfont icon-url"></i>友情链接</h4>
                <div class="flex-fill"></div>
                <a :href="hm.link.contact"><i class="io io-xiaoxi icon-fw mr-1"></i>向站长申请<i class="iconfont icon-arrow-r-m"></i></a>
              </div>
              <div class="friendlink card mb-3 mb-md-4">
                <div class="card-body">
                  <a v-for="item in yqList">{{ item.name }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <asideDrawer :showGoback="false" :navMenuList="navMenuList" />
  </div>
</template>
<script>
import Class from '../class/class'
import asideBar from '@/views/layout/components/aside'
import asideDrawer from '@/views/layout/components/asideDrawer'
import { API_URL } from '@/utils/request.js'
import { GetArticle, getCat, getNav, guanggao, getNewsRand, setBrowse, gethead } from '@/api/http'
export default {
  components: {
    Class,
    asideBar,
    asideDrawer
  },
  data() {
    return {
      hm: {
        theme: {
          mainColor: '#2254f4',
          mainColorActive: '#1f4bdb',
          mainColor10: 'rgba(34, 84, 244, .1)',
          mainColor20: 'rgba(34, 84, 244, .2)',
          mainColor30: 'rgba(34, 84, 244, .3)',
          mainColor50: 'rgba(34, 84, 244, .5)',
          mainColor60: 'rgba(34, 84, 244, .6)'
        },
        curDomain: 'https://huamao.vip/',
        userInfo: {},
        link: {
          passApiUrl:
            'https://api.colorpuss.com/user/auth/pass/?callback_url=https%3A%2F%2Fhuamao.vip%2F&auth_callback_url=https%3A%2F%2Fhuamao.vip%2Fapi%2Fuser%2FsaveCertificate%2F&auth_page_url=https%3A%2F%2Fwww.colorpuss.com%2Flogin%2F',
          contact: '/about/#l-contact',
          qqCs: 'http://wpa.qq.com/msgrd?v=3&amp;uin=1147979142&amp;site=qq&amp;menu=yes',
          search: '/s/',
          searchAssociate: 'https://huamao.vip/api/associate/search/'
        }
      },
      drawerDialog: false,
      activeName: 'zn',
      tabsList: [
        {
          title: '站内',
          href: '',
          color: '#2254f4',
          key: 'zn'
        },
        {
          title: '必应',
          href: 'https://www.bing.com/search?q=',
          color: '#2254f4',
          key: 'by'
        },
        {
          title: 'Yandex',
          href: 'https://yandex.com/search/?text=',
          color: 'rgb(255, 204, 0)',
          key: 'Yandex'
        },
        {
          title: '谷歌',
          href: 'https://www.google.com/search?q=',
          color: '#2254f4',
          key: 'gg'
        },
        {
          title: '百度',
          href: 'https://www.baidu.com/s?wd=',
          color: 'rgb(78, 110, 242)',
          key: 'bd'
        },
        {
          title: '360',
          href: 'https://www.so.com/s?q=',
          color: 'rgb(15, 178, 100)',
          key: 'L360'
        },
        {
          title: '搜狗',
          href: 'rgb(255, 90, 52)',
          color: 'https://www.sogou.com/web?query=',
          key: 'ss'
        },
        {
          title: '头条',
          href: 'https://so.toutiao.com/search?keyword=',
          color: 'rgb(240, 65, 66)',
          key: 'tt'
        }
      ],
      navMenuList: [],
      optionsList: [],
      loading: false,
      searchValue: '',
      searAction: {},
      bannerList: [], //轮播图
      resouList: [],
      guanggaoList: [],
      ziXunList: [],
      restaurants: [
        {
          value: '三全鲜食（北新泾店）',
          address: '长宁区新渔路144号'
        },
        {
          value: 'Hot honey 首尔炸鸡（仙霞路）',
          address: '上海市长宁区淞虹路661号'
        },
        {
          value: '新旺角茶餐厅',
          address: '上海市普陀区真北路988号创邑金沙谷6号楼113'
        },
        {
          value: '泷千家(天山西路店)',
          address: '天山西路438号'
        },
        {
          value: '胖仙女纸杯蛋糕（上海凌空店）',
          address: '上海市长宁区金钟路968号1幢18号楼一层商铺18-101'
        }
      ],
      yqList: [],
      backgroundImg: '',
      deviceType: '',
      gugeList: []
    }
  },
  computed: {
    isCollapse() {
      return this.$store.state.collapse
    },
    isMobile() {
      return this.$store.state.isMobile
    },
    isDrawer() {
      return this.$store.state.isDrawer
    }
  },
  watch: {
    isDrawer(val) {
      this.drawerDialog = val
    }
  },
  mounted() {
    this.adjustStyle()
  },
  created() {
    this.GetArticleContent()
    this.getZiXun()
    this.getNavList()
    this.getBaijin()
    this.getYq()
  },
  methods: {
    adjustStyle() {
      var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      console.log(width)
    },

    beforeClose() {
      if (this.drawerDialog) {
        this.$store.commit('toggleDrawer', false)
      }
    },
    //友情链接
    async getYq() {
      var query = {
        type: 3
      }
      const data = await gethead(query)
      this.yqList = data.data.data
      this.yqList = this.yqList.reverse()
    },
    async getNavList() {
      const res = await getNav()
      var getnavList = res.data.data

      var iconParam = {
        花猫联盟: 'io io-VIP',
        搜索引擎: 'io io-sousuo',
        休闲娱乐: 'io io-women',
        工作办公: 'io io-gonggao',
        网络科技: 'io io-faxian',
        学习教育: 'io io-renwu',
        AI工具: 'io io-yinle',
        自媒体平台: 'io io-zhibo'
      }
      getnavList.forEach((item) => {
        item.image = API_URL + item.image
        item.icon = item.icon || iconParam[item.name]
        var aa = {
          id: item.id,
          name: item.name,
          ref: 'section2',
          icon: item.image,
          isShowMore: true,
          children: []
        }
        if (item.nav) {
          item.nav.forEach((item1) => {
            item1.image = API_URL + item1.image
            var cc = {
              img: item1.image,
              name: item1.title,
              desc: item1.content,
              isShowMoreTag: true,
              tags: item1.tag.split(/\s*,\s*/),
              id: item1.id
            }
            aa.children.push(cc)
          })
        }
        // this.navMenuList = [...getnavList].reverse()
        this.navMenuList = [...getnavList].reverse()
      })
    },
    async goDetails(item, items) {
      console.log(item.link)
      window.open('https://' + item.link, '_blank')
      // var query = {
      // 	id: item.id
      // }
      // const setBrowses = await setBrowse(query)
      // this.$router.push({
      // 	path: '/details',
      // 	query: {
      // 		id: item.id,
      // 		cid: items.id
      // 	}
      // })
      // this.$router.push('/details')
    },

    clickJump(val) {
      this.$router.push(val)
    },
    goZiXun(item) {
      this.$router.push({
        path: '/zixun',
        query: {
          id: item.id
        }
      })
    },
    clickJump3(res) {
      //   console.log('---res----res-----', JSON.stringify(val))
      if (res.link !== '/') {
        window.open('https://' + res.link, '_blank')
      }
    },
    remoteMethod(query) {
      this.optionsList = []
      this.optionsList.push({
        label: query,
        value: query
      })
    },
    async getBaijin() {
      var data1 = {
        type: 0
      }
      const baijin = await guanggao(data1)
      var guanggap = baijin.data.data
      guanggap.forEach((item) => {
        item.image = API_URL + item.image
      })
      this.guanggaoList = guanggap
      var query = {
        type: 1
      }
      const data = await guanggao(query)
      console.log(data.data.data, '-----------')
      var res = data.data.data
      res.forEach((item) => {
        item.image = API_URL + item.image
      })
      this.gugeList = res
    },
    async GetArticleContent() {
      const data = await GetArticle()

      var res = data.data.data
      var banner = res.banner
      banner.forEach((item) => {
        item.image = API_URL + item.image
      })
      this.bannerList = banner
      if (localStorage.getItem('keyTitle') === 'Android') {
        var android = res.Android
        android.forEach((item) => {
          if (item.name === 'Android_search') {
            var resou = item.value.split(/\s*,\s*/)
            this.resouList = resou
          }
          if (item.name === 'Android_background_img') {
            this.backgroundImg = API_URL + item.value
          }
        })
      } else {
        var ios = res.Ios
        ios.forEach((item) => {
          if (item.name === 'Ios_search') {
            var resou = item.value.split(/\s*,\s*/)
            this.resouList = resou
          }
          if (item.name === 'Ios_background_img') {
            this.backgroundImg = API_URL + item.value
          }
        })
      }
      //    for (const key in res) {

      //      if (res.hasOwnProperty(key) && typeof res[key] === 'object') {

      //        if (res[key].name === 'search') {
      //          var resou = res[key].value.split(/\s*,\s*/)
      //          this.resouList = resou;

      //        }
      //        if (res[key].name === 'background_img') {
      //          this.backgroundImg = API_URL + res[key].value
      //        }
      //      }
      //    }
    },
    //获取资讯
    async getZiXun() {
      const zixun = await getNewsRand()
      this.ziXunList = zixun.data.data
      const now = new Date().getTime()
      this.ziXunList.forEach((item) => {
        const createtime = parseInt(item.createtime * 1000)

        const diff = now - createtime
        let timeAgo = ''

        if (diff < 60) {
          timeAgo = `${diff} 秒前`
        } else if (diff < 3600) {
          timeAgo = `${Math.floor(diff / 60)} 分钟前`
        } else if (diff < 86400) {
          timeAgo = `${Math.floor(diff / 3600)} 小时前`
        } else {
          const days = Math.floor(diff / 86400)
          timeAgo = `${days} 天前`
        }

        item.timeAgo = timeAgo // 将计算出的时间差添加到原始对象中
      })
    },
    // 点击切换
    handleClick() {
      this.searAction = this.tabsList.filter((res) => res.key == this.activeName)[0]
      // searAction
    },
    // 点击搜索
    searchClick() {
      // this.$router.push({ path: '/zixun', query: { id: item.id} })
      if (this.activeName == 'zn') {
        this.$router.push({
          path: '/globalSearch',
          query: {
            searchValue: this.searchValue
          }
        })
      } else {
        window.open(this.searAction.href + this.searchValue)
      }
    },
    methodStat(tag) {
      return tag ? tag.split(',') : []
    },
    // 搜索方法
    querySearch(queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    timeAgo(timestamp) {
      const now = Date.now()
      const secondsDiff = Math.floor((now - timestamp) / 1000) // 秒差
      const minutesDiff = Math.floor(secondsDiff / 60) // 分钟差
      const hoursDiff = Math.floor(minutesDiff / 60) // 小时差
      const daysDiff = Math.floor(hoursDiff / 24) // 天数差
      const monthsDiff = Math.floor(daysDiff / 30) // 月份差，假设每个月30天
      const yearsDiff = Math.floor(daysDiff / 365) // 年数差

      if (secondsDiff < 60) {
        return `${secondsDiff} 秒前`
      } else if (minutesDiff < 60) {
        return `${minutesDiff} 分钟前`
      } else if (hoursDiff < 24) {
        return `${hoursDiff} 小时前`
      } else if (daysDiff < 30) {
        return `${daysDiff} 天前`
      } else if (monthsDiff < 12) {
        return `${monthsDiff} 个月前`
      } else {
        return `${yearsDiff} 年前`
      }
    },
    clickJump2(val) {
      window.open(val, '_blank')
    }
  }
}
</script>
<style scoped lang="less">
@import url('../css/bootstrap.css');
@import url('../css/style.css');

.contens p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pc_guge {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 30px;
}
.pc_guge .item1 {
  width: 130px;
  margin: 10px;
}
.pc_guge .item1 .top img {
  width: 130px;
  height: 130px;
  border-radius: 10px;
}
.pc_guge .item1 .top1 {
  margin-top: 10px;
  font-weight: bold;
}
.guang_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 30px;
}
.guang_list .item1 {
  border-radius: 10px;
}
.guang_list .item1 .top img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}
.guang_list .item1 .top1 {
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 10px;
  font-weight: bold;
}
.index_one {
  width: 100%;
}
.list .left img {
  width: 30px;
  height: 30px;
}
.list .left {
  display: flex;
  align-items: center;
}
.list .left p {
  overflow: hidden;
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 10px;
}
.list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0;
  width: 90vw;
}
.list .item1s {
  width: 48%;
  padding: 10px;
  display: flex;
  align-items: center;
  background: #f1f4f9;
  margin-top: 10px;
  border-radius: 10px;
  justify-content: space-between;
}
.menu-icon {
  display: inline-block;
  cursor: pointer;
  width: 25px;
  height: 20px;
  position: relative;
}

.barDiv {
  background-color: black;
  position: absolute;
  left: 0;
  transition: width 0.3s ease-in-out;
  height: 3px;
  width: 100%;
  border-radius: 1.5px;
}

.top {
  top: 0;
}

.middle {
  top: 50%;
  transform: translateY(-50%);
  transition: none;
  /* No transition for the middle bar */
}

.bottom {
  bottom: -1px;
}

.barDiv.active {
  width: 60%;
  /* Change width to 50% when active */
}

/* 轮播部分 */
.carouselImg {
  width: 100%;
}

.carousel {
  position: relative;
}

.carouselDiv {
  width: 1100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.s-type-list {
  display: flex !important;
}

/* 白金赞助 */

.urlImg {
  width: 100%;
  border-radius: 0.625rem;
  background-color: #f1f4f9;
  transition: background-color 0.3s;
  will-change: transform;
  overflow: hidden;
  border: 2px solid var(--main-color-50);
  box-shadow: 0 5px 20px -3px var(--main-color-20);
  cursor: pointer;
}

.card-grid2 {
  padding: 0;
  margin: 0;
  display: flex;
}

.platinum {
  .url-card {
    cursor: pointer;

    .urlImg {
      height: 160px;
    }
  }
}

.card-grid2 .url-card:hover .urlImg {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

@media (max-width: 1000px) {
  .platinum {
    .url-card {
      width: 50%;
      height: 120px;

      .urlImg {
        height: 100%;
      }
    }
  }
}
</style>
<style>
.mini-sidebar .slide-bar-ul .slide-bar span {
  display: none;
}

/* 搜索部分 */
.searchDiv {
  border: Red 1px solid;
  display: flex;
  border: #2254f4 2px solid;
  border-radius: 10px;
  overflow: hidden;
  height: 50px;
}

.searchDiv .el-autocomplete {
  width: calc(100% - 104px);
  height: 50px;
}

.searchDiv input {
  border: transparent 1px solid !important;
  height: 50px !important;
}

.searchDiv .el-input__inner::-webkit-input-placeholder {
  color: #888;
  font-size: 1rem;
}

.searchDiv .searchSlot {
  width: 104px;
  cursor: pointer;
  display: block;
  font-size: 1.4rem;
  background: #2254f4;
  color: #fff;
  text-align: center;
  line-height: 50px;
}

.Yandex {
  border: rgb(255, 204, 0) 2px solid !important;
}

.Yandex .searchSlot {
  background: rgb(255, 204, 0);
}

.bd {
  border: rgb(78, 110, 242) 2px solid !important;
}

.bd .searchSlot {
  background: rgb(78, 110, 242);
}

.L360 {
  border: rgb(15, 178, 100) 2px solid !important;
}

.L360 .searchSlot {
  background: rgb(15, 178, 100);
}

.ss {
  border: rgb(255, 90, 52) 2px solid !important;
}

.ss .searchSlot {
  background: rgb(255, 90, 52);
}

.tt {
  border: rgb(240, 65, 66) 2px solid !important;
}

.tt .searchSlot {
  background: rgb(240, 65, 66);
}

/* 搜索上面的切换 */
.tapTabs {
  margin-top: 5px;
}

.tapTabs .el-tabs__nav-wrap::after {
  display: none;
}

.tapTabs .el-tabs__active-bar {
  background: #2254f4;
  height: 3px;
  border-radius: 3px;
}

.tapTabs .el-tabs__content {
  display: none;
}

.tapTabs .el-tabs__header {
  margin: 0;
}

.tapTabs .el-tabs__item {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  word-break: keep-all;
  cursor: pointer;
  color: #666;
}

.tapTabs .el-tabs__item:hover {
  color: #000;
  cursor: pointer;
}

.tapTabs .is-active {
  color: #000;
}

@media (min-width: 1200px) {
  .el-carousel__item {
    border-radius: 15px;
    overflow: hidden;
  }

  .el-carousel__item img {
    height: 100% !important;
  }

  .carouselDiv .el-carousel__container .is-active {
    width: 950px;
    margin-left: -200px;
    position: relative;
  }

  .carouselDiv .el-carousel__container .is-active img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto !important;
    border-radius: 15px;
  }

  .carouselImage {
    display: block !important;
  }

  .carouselImageWap {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .carouselImage {
    display: none !important;
  }

  .carouselImageWap {
    display: block !important;
  }
}
</style>
